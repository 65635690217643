import React, { useEffect, useState, useCallback } from "react";
import { connect } from "react-redux";
import withStyles from "@material-ui/core/styles/withStyles";
import Button from "@material-ui/core/Button";
import { Theme } from "@material-ui/core/styles/createMuiTheme";
import FormControl from "@material-ui/core/FormControl";
import FormHelperText from "@material-ui/core/FormLabel";
import Input from "@material-ui/core/Input";
import InputLabel from "@material-ui/core/InputLabel";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import DatePicker from "react-datepicker";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import FormLabel from "@material-ui/core/FormLabel";

import "react-datepicker/dist/react-datepicker.css";

import { StoreState } from "../../redux/configure-store";
import { createInvoice } from "../admin-actions";
import Loading from "../../common/components/Loading"
import {
  calculateTotalHoursForTimesheets,
  formatTimesheetDate,
  generateInvoiceId,
  transformMinutesTimeFormatToDecimals
} from "../../common/utils"
import dayjs from "../../common/date";
import { Invoice, Timesheet } from "../types"

const styles: any = (theme: Theme) => ({
  main: {
    width: "auto",
    display: "block", // Fix IE 11 issue.
    marginLeft: theme.spacing(3),
    marginRight: theme.spacing(3),
    [theme.breakpoints.up(400 + theme.spacing(3) * 2)]: {
      width: 400,
      marginLeft: "auto",
      marginRight: "auto",
    },
  },
  paper: {
    marginTop: theme.spacing(8),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    padding: `${theme.spacing(2)}px ${theme.spacing(3)}px ${theme.spacing(
      3
    )}px`,
  },
  avatar: {
    margin: theme.spacing(),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(),
  },
  submit: {
    marginTop: theme.spacing(3),
  },
});

const createInitialErrorState = () => ({
  publicId: "",
  totalNumberOfHours: "",
  periodStartDate: "",
  periodEndDate: "",
  paymentDueAt: "",
  secondaryItemAmount: "",
  secondaryItemDescription: "",
  salesTaxAmount: "",
  totalPaymentAmount: "",
  currency: "",
  paymentMethod: "",
});

function AdminInvoiceCreate(props: any) {
  const { allInvoices, classes, engagement, isLoading, allTimesheets, selectedTimesheetIds } = props;

  const [formData, setFormData] = useState({
    publicId: "",
    engagementId: null,
    userId: null,
    totalNumberOfHours: "0.00",
    secondaryItemAmount: 0.0,
    secondaryItemDescription: null,
    periodStartDate: null,
    periodEndDate: null,
    paymentDueAt: null,
    currency: null,
    paymentMethod: "",
    salesTaxAmount: "0.00",
    totalPaymentAmount: 0.0,
  });

  const [errors, setErrors] = useState(createInitialErrorState());

  const onChange = useCallback((e: any) => {
    const name = e.target.name;
    const value = e.target.value;
    setFormData((prevState: any) => ({ ...prevState, [name]: value }));
    const hourlyRate = engagement.hourlyRate;
    setFormData((prevState: any) => {
      const salesTaxAmount = parseFloat(prevState.salesTaxAmount || "0");
      const secondaryItemAmount = parseFloat(
        prevState.secondaryItemAmount || "0"
      );
      return {
        ...prevState,
        totalPaymentAmount:
          salesTaxAmount +
          secondaryItemAmount +
          hourlyRate * parseFloat(prevState.totalNumberOfHours),
      };
    });
  }, [engagement]);

  useEffect(() => {
    if (isLoading) {
      return;
    }
    const invoicesForEngagement = allInvoices
      .filter((i: Invoice) => i.engagement.id === engagement.id)
      .sort((a: Invoice, b: Invoice) => new Date(a.createdAt).getTime() - new Date(b.createdAt).getTime());
    if (!invoicesForEngagement.length) {
      return;
    }
    const lastInvoice = invoicesForEngagement[invoicesForEngagement.length - 1]
    onChange({
      target: {
        name: "publicId",
        value: generateInvoiceId(lastInvoice.publicId),
      }
    });
    onChange({
      target: {
        name: "paymentMethod",
        value: engagement.user.paymentMethod,
      }
    });
  }, [isLoading, engagement, onChange, allInvoices]);

  useEffect(() => {
    if (isLoading) {
      return;
    }
    const _selectedTimesheets = allTimesheets
      .filter((t: Timesheet) => selectedTimesheetIds.includes(t.id))
      .sort((a: Timesheet, b: Timesheet) => a.id - b.id)
    if (!_selectedTimesheets.length) {
      return;
    }

    const totalHoursInMinutesOrDecimals = calculateTotalHoursForTimesheets(_selectedTimesheets);
    onChange({
      target:
      {
        name: "totalNumberOfHours",
        value: _selectedTimesheets[0].timeFormat === 'decimals'
          ? totalHoursInMinutesOrDecimals
          : transformMinutesTimeFormatToDecimals(totalHoursInMinutesOrDecimals)
      }
    });
    handlePeriodStartDateChange(new Date(formatTimesheetDate(_selectedTimesheets[0].startDate)))
    const endDate = new Date(formatTimesheetDate(_selectedTimesheets[_selectedTimesheets.length - 1].endDate));
    handlePeriodEndDateChange(endDate)
    handlePaymentDueAtChange(dayjs(endDate).add(20, 'day').toDate());
  }, [isLoading, onChange, selectedTimesheetIds, allTimesheets]);

  function handlePeriodStartDateChange(date: Date) {
    setFormData((prevState: any) => (
      { ...prevState, periodStartDate: date }
    ));
  };

  function handlePeriodEndDateChange(date: Date) {
    setFormData((prev: any) => ({
      ...prev,
      periodEndDate: date
    }));
  };

  function handlePaymentDueAtChange(date: Date) {
    setFormData((prev: any) => ({ ...prev, paymentDueAt: date }));
  };

  function handlePaymentMethodChange(e: any) {
    setFormData((prev: any) => ({ ...prev, paymentMethod: e.target.value }));
  }

  function onSubmit(e: any) {
    e.preventDefault();
    setErrors(createInitialErrorState());

    const emptyValueErrorMessage = "Value can't be empty";
    const _errors = createInitialErrorState();

    if (formData.publicId.length === 0) {
      _errors.publicId = emptyValueErrorMessage;
    }

    if (formData.totalNumberOfHours.length === 0) {
      _errors.totalNumberOfHours = emptyValueErrorMessage;
    }

    if (!formData.periodStartDate) {
      _errors.periodStartDate = emptyValueErrorMessage;
    }

    if (!formData.periodEndDate) {
      _errors.periodEndDate = emptyValueErrorMessage;
    }

    if (!formData.paymentDueAt) {
      _errors.paymentDueAt = emptyValueErrorMessage;
    }

    if (!formData.paymentMethod) {
      _errors.paymentMethod = emptyValueErrorMessage;
    }

    if (formData.salesTaxAmount === "") {
      _errors.salesTaxAmount = emptyValueErrorMessage;
    }

    if (formData.secondaryItemAmount !== 0 && isNaN(formData.secondaryItemAmount)) {
      _errors.secondaryItemAmount = "Secondary Item amount must be a number.";
    }

    if (isNaN(formData.totalPaymentAmount)) {
      _errors.totalPaymentAmount = "This amount is not valid";
    }

    setErrors(_errors);

    const hasErrors = Object.values(_errors).some(
      (error: string) => error.length !== 0
    );

    if (!hasErrors) {
      props.createInvoice({
        publicId: formData.publicId,
        engagementId: engagement.id,
        userId: engagement.user.id,
        currency: engagement.currency,
        paymentMethod: formData.paymentMethod,
        salesTaxAmount: parseFloat(formData.salesTaxAmount),
        secondaryItemAmount: formData.secondaryItemAmount,
        secondaryItemDescription: formData.secondaryItemDescription,
        totalPaymentAmount: formData.totalPaymentAmount,
        totalNumberOfHours: parseFloat(formData.totalNumberOfHours),
        periodStartDate: formData.periodStartDate,
        periodEndDate: formData.periodEndDate,
        paymentDueAt: formData.paymentDueAt,
      });
    }
  };

  if (isLoading) {
    return <Loading></Loading>
  }

  if (!engagement || !engagement.id) {
    return <>Error. You must select an engagement</>;
  }
  return (
    <main className={classes.main}>
      <Paper elevation={2} className={classes.paper}>
        <Typography component="h1" variant="h5">
          Create an Invoice
        </Typography>
        <p>Client: {engagement.clientName}</p>
        <p style={{ margin: "0" }}>
          Developer: {engagement.user.firstName}{" "}
          {engagement.user.lastName}
        </p>
        <form className={classes.form} onSubmit={onSubmit}>
          <FormControl margin="normal" fullWidth error={!!errors.publicId}>
            <InputLabel htmlFor="publicId" required>
              Invoice Id
            </InputLabel>
            <Input
              id="publicId"
              name="publicId"
              autoFocus
              autoComplete="off"
              value={formData.publicId}
              onChange={onChange}
            />
            {errors.publicId && (
              <FormHelperText>{errors.publicId}</FormHelperText>
            )}
          </FormControl>
          <FormControl
            margin="normal"
            fullWidth
            error={!!errors.totalNumberOfHours}
          >
            <InputLabel htmlFor="totalNumberOfHours" required>
              Number Of Hours
            </InputLabel>
            <Input
              id="totalNumberOfHours"
              name="totalNumberOfHours"
              autoComplete="off"
              value={formData.totalNumberOfHours}
              onChange={onChange}
            />
            {errors.totalNumberOfHours && (
              <FormHelperText>{errors.totalNumberOfHours}</FormHelperText>
            )}
          </FormControl>

          <FormControl
            margin="normal"
            fullWidth
            error={!!errors.secondaryItemAmount}
          >
            <InputLabel htmlFor="secondaryItemAmount">
              Optional Secondary Item Amount
            </InputLabel>
            <Input
              id="secondaryItemAmount"
              name="secondaryItemAmount"
              autoComplete="off"
              value={formData.secondaryItemAmount}
              onChange={onChange}
            />
            {errors.secondaryItemAmount && (
              <FormHelperText>{errors.secondaryItemAmount}</FormHelperText>
            )}
          </FormControl>

          <FormControl
            margin="normal"
            fullWidth
            error={!!errors.secondaryItemDescription}
          >
            <InputLabel htmlFor="secondaryItemDescription">
              Optional Secondary Item Description
            </InputLabel>
            <Input
              id="secondaryItemDescription"
              name="secondaryItemDescription"
              autoComplete="off"
              value={formData.secondaryItemDescription}
              onChange={onChange}
            />
            {errors.secondaryItemDescription && (
              <FormHelperText>
                {errors.secondaryItemDescription}
              </FormHelperText>
            )}
          </FormControl>

          <FormControl
            margin="normal"
            fullWidth
            error={!!errors.salesTaxAmount}
          >
            <InputLabel htmlFor="salesTaxAmount" required>
              Sales Tax Amount
            </InputLabel>
            <Input
              id="salesTaxAmount"
              name="salesTaxAmount"
              autoComplete="off"
              value={formData.salesTaxAmount}
              onChange={onChange}
            />
            {errors.salesTaxAmount && (
              <FormHelperText>{errors.salesTaxAmount}</FormHelperText>
            )}
          </FormControl>
          <FormControl
            margin="normal"
            fullWidth
            error={!!errors.totalPaymentAmount}
          >
            <p>Total Payment Amount</p>
            <p style={{ margin: "0" }}>{formData.totalPaymentAmount}</p>
            {errors.totalPaymentAmount && (
              <FormHelperText>{errors.totalPaymentAmount}</FormHelperText>
            )}
          </FormControl>
          <FormControl margin="normal" fullWidth error={!!errors.currency}>
            <InputLabel htmlFor="currency" required>
              Currency
            </InputLabel>
            <Input
              id="currency"
              name="currency"
              autoComplete="off"
              disabled={true}
              value={engagement.currency}
            />
          </FormControl>
          <FormControl margin="normal" fullWidth error={!!errors.paymentMethod}>
            <InputLabel htmlFor="paymentMethod" required>PaymentMethod</InputLabel>
            <Select
              labelId="payment-method-label"
              id="payment-method"
              value={formData.paymentMethod}
              label="Payment Method"
              name="paymentMethod"
              onChange={handlePaymentMethodChange}
            >
              <MenuItem value="wise">Wise</MenuItem>
              <MenuItem value="bmoUsd">Bmo USD</MenuItem>
              <MenuItem value="bitcoin">Bitcoin</MenuItem>
              <MenuItem value="stablecoin">Stablecoin</MenuItem>
            </Select>
          </FormControl>
          <FormControl
            margin="normal"
            fullWidth
            error={!!errors.periodStartDate}
          >
            <FormLabel component="legend" required>
              Period Start Date
            </FormLabel>
            <DatePicker
              selected={formData.periodStartDate}
              onChange={handlePeriodStartDateChange}
            />
            {errors.periodStartDate && (
              <FormHelperText>{errors.periodStartDate}</FormHelperText>
            )}
          </FormControl>
          <FormControl
            margin="normal"
            fullWidth
            error={!!errors.periodEndDate}
          >
            <FormLabel component="legend" required>
              Period End Date
            </FormLabel>
            <DatePicker
              selected={formData.periodEndDate}
              onChange={handlePeriodEndDateChange}
            />
            {errors.periodEndDate && (
              <FormHelperText>{errors.periodEndDate}</FormHelperText>
            )}
          </FormControl>
          <FormControl
            margin="normal"
            fullWidth
            error={!!errors.paymentDueAt}
          >
            <FormLabel component="legend" required>
              Payment Due Date
            </FormLabel>
            <DatePicker
              selected={formData.paymentDueAt}
              onChange={handlePaymentDueAtChange}
            />
            {errors.paymentDueAt && (
              <FormHelperText>{errors.paymentDueAt}</FormHelperText>
            )}
          </FormControl>
          <Button
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            className={classes.submit}
          >
            Submit
          </Button>
        </form>
      </Paper>
    </main>
  );
}

const mapStateToProps = (storeState: StoreState) => ({
  allTimesheets: storeState.admin.timesheets,
  allInvoices: storeState.admin.invoices,
  selectedTimesheetIds: storeState.admin.selectedTimesheetIds
});

export default connect(mapStateToProps, { createInvoice })(
  withStyles(styles)(AdminInvoiceCreate)
);
