import { createMuiTheme } from "@material-ui/core/styles";

export const primary = {
  light: "#c2fffd",
  main: "#005cff",
  dark: "#00398c",
  contrastText: "#fbfbfb",
};
const secondary = {
  main: "#005cff",
  light: "#ccc",
  dark: "#000000",
  contrastText: "#fbfbfb",
};

const theme = createMuiTheme({
  overrides: {
    MuiTableRow: {
      root: {
        "&$hover:hover": {
          backgroundColor: secondary.main,
        },
      },
    },
    MuiListItem: {
      root: {
        "&$selected, &$selected:hover": {
          backgroundColor: secondary.main,
        },
      },
      button: {
        "&:hover": {
          backgroundColor: secondary.light,
        },
      },
    },
  },
  palette: {
    primary,
    secondary,
  },
  //https://material-ui.com/style/typography/#migration-to-typography-v2
  typography: {},
});

export default theme;
