import React from "react";
import { connect } from "react-redux";
import Paper from "@material-ui/core/Paper";
import { createStyles } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import { Theme } from "@material-ui/core/styles/createMuiTheme";
import Button from "@material-ui/core/Button";
import FormControl from "@material-ui/core/FormControl";
import FormHelperText from "@material-ui/core/FormLabel";
import Input from "@material-ui/core/Input";
import InputLabel from "@material-ui/core/InputLabel";
import Typography from "@material-ui/core/Typography";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import { isEmail } from "validator";

import { navigateToAdminUsersCreateEngagement } from "../../routing/route-actions";
import { StoreState } from "../../redux/configure-store";
import { navigateToAdminPage } from "../../routing/route-actions";
import { isValidPassword, passwordRequirements } from "../../common/utils";

import { updateUserForAdmin } from "../admin-actions";

const styles = (theme: Theme) =>
  createStyles({
    main: {
      width: "auto",
      display: "block", // Fix IE 11 issue.
      marginLeft: theme.spacing(3),
      marginRight: theme.spacing(3),
      [theme.breakpoints.up(400 + theme.spacing(3) * 2)]: {
        width: 400,
        marginLeft: "auto",
        marginRight: "auto",
      },
    },
    paper: {
      marginTop: theme.spacing(3),
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      padding: `${theme.spacing(2)}px ${theme.spacing(3)}px ${theme.spacing(
        3
      )}px`,
    },
    avatar: {
      margin: theme.spacing(),
      backgroundColor: theme.palette.secondary.main,
    },
    form: {
      width: "100%", // Fix IE 11 issue.
      marginTop: theme.spacing(),
    },
    submit: {
      marginTop: theme.spacing(3),
    },
  });

const createInitialErrorState = () => ({
  firstName: "",
  lastName: "",
  country: "",
  numberOfHoursPerWeekAvailable: "",
  hourlyRateInUsd: "",
  shortTechnicalLabel: "",
  technicalDescription: "",
  paymentMethod: "",
  emailAddress: "",
  newPassword: "",
  confirmNewPassword: "",
});

class AdminUsersView extends React.Component<any> {
  state = {
    isInitialized: false,
    data: {
      id: 0,
      isAdmin: false,
      firstName: "",
      lastName: "",
      country: "",
      hourlyRateInUsd: "",
      shortTechnicalLabel: "",
      paymentMethod: "",
      numberOfHoursPerWeekAvailable: 0,
      technicalDescription: "",
      emailAddress: "",
      newPassword: "",
      confirmNewPassword: "",
    },
    errors: createInitialErrorState(),
  };

  initializeForm = () => {
    const { selectedUser } = this.props;

    this.setState({
      ...this.state,
      isInitialized: true,
      data: {
        ...this.state.data,
        id: selectedUser.id,
        isAdmin: selectedUser.isAdmin,
        firstName: selectedUser.firstName || "",
        lastName: selectedUser.lastName || "",
        emailAddress: selectedUser.emailAddress || "",
        technicalDescription: selectedUser.technicalDescription || "",
        country: selectedUser.country || "",
        hourlyRateInUsd: selectedUser.hourlyRateInUsd || "",
        shortTechnicalLabel: selectedUser.shortTechnicalLabel || "",
        paymentMethod: selectedUser.paymentMethod || "",
        numberOfHoursPerWeekAvailable:
          selectedUser.numberOfHoursPerWeekAvailable || 0,
        newPassword: "",
        confirmNewPassword: "",
      },
      errors: createInitialErrorState(),
    });
  };

  onChangeConfirmationDialogStatus = (isOpened: boolean) => {
    this.setState({
      ...this.state,
    });
  };

  onChange = (e: any) => {
    this.setState({
      data: { ...this.state.data, [e.target.name]: e.target.value },
    });
  };

  onChangeAdminStatus = async () => {
    const { data } = this.state;
    let newData = data;
    newData.isAdmin = !data.isAdmin;
    await this.props.updateUserForAdmin(data);
  };

  onSubmit = async (e: any) => {
    e.preventDefault();
    const { updateUserForAdmin, navigateToAdminPage } = this.props;
    const { data } = this.state;
    const errors = createInitialErrorState();
    this.setState({ errors });

    if (data.firstName.length === 0) {
      errors.firstName = "Can't be void";
    }

    if (data.lastName.length === 0) {
      errors.lastName = "Can't be void";
    }

    if (!data.emailAddress || !isEmail(data.emailAddress))
      errors.emailAddress = "Invalid email";

    if (data.newPassword && !isValidPassword(data.newPassword))
      errors.newPassword = "Password is too weak";

    if (data.newPassword !== data.confirmNewPassword) {
      errors.confirmNewPassword = "Passwords do not match";
    }

    const hasErrors = Object.values(errors).some(
      (error: string) => error.length !== 0
    );

    if (!hasErrors) {
      await updateUserForAdmin(data);
      navigateToAdminPage();
    } else {
      this.setState({ errors });
    }
  };

  render() {
    const { classes, selectedUser, isEditingUser } = this.props;
    const { isInitialized, data, errors } = this.state;

    if (!selectedUser) return null;

    if (!isInitialized) {
      this.initializeForm();
    }

    return (
      <main className={classes.main}>
        <Button
          onClick={() =>
            this.props.navigateToAdminUsersCreateEngagement(data.id)
          }
          variant="contained"
          color="secondary"
        >
          Create Engagement
        </Button>
        <Paper elevation={2} className={classes.paper}>
          <Typography component="h1" variant="h5">
            Edit User
          </Typography>
          <form className={classes.form} onSubmit={this.onSubmit}>
            <FormControl margin="normal" fullWidth>
              <InputLabel htmlFor="firstName">User Id</InputLabel>
              <Input
                id="selectedUsedId"
                name="selectedUserId"
                disabled
                value={data.id}
              />
            </FormControl>

            <FormControl margin="normal" fullWidth error={!!errors.firstName}>
              <InputLabel htmlFor="firstName">First Name</InputLabel>
              <Input
                id="firstName"
                name="firstName"
                autoFocus
                autoComplete="off"
                value={data.firstName}
                onChange={this.onChange}
              />
              {errors.firstName && (
                <FormHelperText>{errors.firstName}</FormHelperText>
              )}
            </FormControl>

            <FormControl margin="normal" fullWidth error={!!errors.lastName}>
              <InputLabel htmlFor="lastName">Last Name</InputLabel>
              <Input
                id="lastName"
                name="lastName"
                autoComplete="off"
                value={data.lastName}
                onChange={this.onChange}
              />
              {errors.lastName && (
                <FormHelperText>{errors.lastName}</FormHelperText>
              )}
            </FormControl>

            <FormControl
              margin="normal"
              fullWidth
              error={!!errors.emailAddress}
            >
              <InputLabel htmlFor="emailAddress" required>
                Email Address
              </InputLabel>
              <Input
                id="emailAddress"
                name="emailAddress"
                value={data.emailAddress}
                autoComplete="off"
                onChange={this.onChange}
              />
              {errors.emailAddress && (
                <FormHelperText>{errors.emailAddress}</FormHelperText>
              )}
            </FormControl>

            <FormControl
              margin="normal"
              fullWidth
              error={!!errors.technicalDescription}
            >
              <InputLabel htmlFor="technicalDescription">
                Technical Description
              </InputLabel>
              <Input
                id="technicalDescription"
                name="technicalDescription"
                multiline={true}
                rows={5}
                value={data.technicalDescription}
                autoComplete="off"
                onChange={this.onChange}
              />
              {errors.technicalDescription && (
                <FormHelperText>{errors.technicalDescription}</FormHelperText>
              )}
            </FormControl>

            <FormControl margin="normal" fullWidth error={!!errors.country}>
              <InputLabel htmlFor="country">Country</InputLabel>
              <Input
                id="country"
                name="country"
                autoComplete="off"
                value={data.country}
                onChange={this.onChange}
              />
              {errors.country && (
                <FormHelperText>{errors.country}</FormHelperText>
              )}
            </FormControl>

            <FormControl
              margin="normal"
              fullWidth
              error={!!errors.numberOfHoursPerWeekAvailable}
            >
              <InputLabel htmlFor="numberOfHoursPerWeekAvailable">
                Hours Per Week Available
              </InputLabel>
              <Input
                id="numberOfHoursPerWeekAvailable"
                name="numberOfHoursPerWeekAvailable"
                autoComplete="off"
                value={data.numberOfHoursPerWeekAvailable}
                onChange={this.onChange}
              />
              {errors.numberOfHoursPerWeekAvailable && (
                <FormHelperText>
                  {errors.numberOfHoursPerWeekAvailable}
                </FormHelperText>
              )}
            </FormControl>

            <FormControl
              margin="normal"
              fullWidth
              error={!!errors.hourlyRateInUsd}
            >
              <InputLabel htmlFor="hourlyRateInUsd">
                Hourly Rate In USD
              </InputLabel>
              <Input
                id="hourlyRateInUsd"
                name="hourlyRateInUsd"
                autoComplete="off"
                value={data.hourlyRateInUsd}
                onChange={this.onChange}
              />
              {errors.hourlyRateInUsd && (
                <FormHelperText>{errors.hourlyRateInUsd}</FormHelperText>
              )}
            </FormControl>

            <FormControl
              margin="normal"
              fullWidth
              error={!!errors.shortTechnicalLabel}
            >
              <InputLabel htmlFor="shortTechnicalLabel">
                Short Technical Label (frontend, backend, ...)
              </InputLabel>
              <Input
                id="shortTechnicalLabel"
                name="shortTechnicalLabel"
                autoComplete="off"
                value={data.shortTechnicalLabel}
                onChange={this.onChange}
              />
              {errors.shortTechnicalLabel && (
                <FormHelperText>{errors.shortTechnicalLabel}</FormHelperText>
              )}
            </FormControl>

            <FormControl
              margin="normal"
              fullWidth
              error={!!errors.paymentMethod}
            >
              <InputLabel htmlFor="paymentMethod">PaymentMethod</InputLabel>
              <Select
                labelId="payment-method-label"
                id="payment-method"
                value={data.paymentMethod}
                label="Payment Method"
                name="paymentMethod"
                onChange={this.onChange}
              >
                <MenuItem value="wise">Wise</MenuItem>
                <MenuItem value="bmoUsd">Bmo USD</MenuItem>
                <MenuItem value="bitcoin">Bitcoin</MenuItem>
                <MenuItem value="stablecoin">Stablecoin</MenuItem>
              </Select>
              {errors.paymentMethod && (
                <FormHelperText>{errors.paymentMethod}</FormHelperText>
              )}
            </FormControl>

            <FormControl margin="normal" fullWidth error={!!errors.newPassword}>
              <InputLabel htmlFor="newPassword">Change Password</InputLabel>
              <Input
                name="newPassword"
                type="password"
                id="newPassword"
                autoComplete="off"
                value={data.newPassword}
                onChange={this.onChange}
              />
              {errors.newPassword && (
                <FormHelperText style={{ paddingTop: "10px", fontSize: 13 }}>
                  {passwordRequirements}
                </FormHelperText>
              )}
            </FormControl>

            <FormControl
              margin="normal"
              fullWidth
              error={!!errors.confirmNewPassword}
            >
              <InputLabel htmlFor="confirmPassword">
                Confirm New Password
              </InputLabel>
              <Input
                name="confirmNewPassword"
                type="password"
                id="confirmNewPassword"
                autoComplete="off"
                value={data.confirmNewPassword}
                onChange={this.onChange}
              />
              {errors.confirmNewPassword && (
                <FormHelperText>{errors.confirmNewPassword}</FormHelperText>
              )}
            </FormControl>

            <Button
              className={classes.submit}
              type="submit"
              fullWidth
              variant="contained"
              color="primary"
              disabled={isEditingUser}
            >
              Submit
            </Button>
          </form>
        </Paper>
      </main>
    );
  }
}

const mapStateToProps = (storeState: StoreState) => ({
  isEditingUser: storeState.admin.isEditingUser,
  user: storeState.authentication.user,
});

export default connect(mapStateToProps, {
  updateUserForAdmin,
  navigateToAdminUsersCreateEngagement,
  navigateToAdminPage,
})(withStyles(styles)(AdminUsersView));
